import React, { useEffect, useState } from "react";
import { Modal, Row, Col, CloseButton } from "react-bootstrap";
import "./SurveyModal.css";
const SurveyModal = ({ ...props }) => {
  const text = {
    fi: {
      openSurvey: "Avaa kysely",
      skip: "Ohita",
    },
    sv: {
      openSurvey: "Gå till enkäten",
      skip: "Stäng",
    },
    en: {
      openSurvey: "Go to the survey",
      skip: "Close",
    },
  };
  const {
    topics,
    language = "fi",
    sleep = 30000,
    start,
    setShowSurvey,
  } = props;
  const [url, setUrl] = useState();
  const [id, setId] = useState();

  const [data, setData] = useState({});
  const [show, setShow] = useState(false);

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const result = await fetch(
          `${process.env.PUBLIC_URL}/data/..data/survey.json`
        );
        if (result.ok) {
          const data = await result.json();
          setUrl(data.url);
          setId(data.id);
        }
      } catch (e) {
        console.log("SurveyModal: missing config.json");
      }
    };
    // fetchConfig();
    if (!url || !id) {
      setUrl(process.env.REACT_APP_PATENTS_COMMUNICATION_URL);
      setId(process.env.REACT_APP_TRADEMARK_COMMUNICATION_ID);
    }
  }, [url, id]);

  useEffect(() => {
    if (!start) return;
    const fetchData = async () => {
      if (!url || !id || !topics.length > 0) {
        return;
      }
      const result = await fetch(
        `${url}/service/${id}?topics=${topics.join(",")}`
      );
      if (result.ok) {
        const data = await result.json();
        if (data.surveys.length > 0) setData(data.surveys[0]);
      }
      setShowSurvey(false);
    };
    fetchData();
  }, [url, id, topics, start, setShowSurvey]);

  useEffect(() => {
    if (!data) return;
    if (!data.id) return;
    const shown = localStorage.getItem("surveyModalShown")?.split(",") || [];
    const now = new Date();
    if (
      localStorage.getItem("surveySkipDay") === now.toISOString().split("T")[0]
    )
      return;
    if (shown.includes(data.id)) return;
    const startDate = new Date(data.startDate);
    const endDate = new Date(data.endDate);
    if (now < startDate || now > endDate) return;
    setTimeout(() => {
      setShow(true);
    }, sleep);
  }, [data, sleep]);

  const handleSkip = () => {
    const now = new Date();
    localStorage.setItem("surveySkipDay", now.toISOString().split("T")[0]);
    setShow(false);
  };
  const handleClose = () => {
    const shown = localStorage.getItem("surveyModalShown")?.split(",") || [];

    shown.push(data.id);
    localStorage.setItem("surveyModalShown", shown);
    setShow(false);
  };
  return (
    <Modal show={show} onHide={props.handleClose} size="lg">
      <Modal.Header closeButton={false} className="survey-modal-header">
        <Modal.Title className="modal-title">
          {data?.data?.message[language]?.title || ""}
        </Modal.Title>
        <CloseButton onClick={() => handleSkip()} />
      </Modal.Header>
      <Modal.Body className="survey-modal-body">
        {data?.data?.message[language]?.body || ""}
      </Modal.Body>
      <Modal.Footer className="survey-modal-footer">
        <button
          className="btn btn-secondary"
          onClick={() => {
            handleSkip();
          }}
        >
          {text[language]?.skip || text["fi"].skip}
        </button>

        <a href={data?.data?.url[language]} target="_blank" rel="noreferrer">
          <button
            onClick={() => {
              handleClose();
            }}
            className="btn btn-primary"
          >
            {text[language]?.openSurvey || text["fi"].openSurvey}
          </button>
        </a>
      </Modal.Footer>
    </Modal>
  );
};

export default SurveyModal;
