import React, { useState, useContext } from "react";
import "./scss/styles/app.scss";
import SearchResultPage from "./views/SearchResultPage";
import MainPage from "./views/MainPage";
import TmrPage from "./views/TmrPage";
import SearchPage from "./views/SearchPage";
import TrademarkDetailsPage from "./views/TrademarkDetailsPage";
import TmrDetailsPage from "./views/TmrDetailsPage";
import RApplication from "./components/RApplication/RApplication";
import KApplication from "./components/KApplication/KApplication";
import Opposition from "./components/Opposition/Opposition";
import SurveyModal from "./components/common/SurveyModal";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import useEndpoint from "./customHooks/useEndpoint";
import useLocalStorage from "./customHooks/useLocalStorage";
import { initialSearchParams } from "./constants/constants";
import { I18nContext } from "./i18n";
require("dotenv").config();

// Add meta tag for build time

const baseUrl = process.env.REACT_APP_API_URL;
const buildTime = process.env.REACT_APP_BUILD_TIME;

const meta = document.createElement("meta");
meta.name = "bt";
meta.content = new Date(buildTime * 1000);
document.head.appendChild(meta);

function buildRoutePath(path) {
  const noLangInPath = !/\/:lang\??(\/|$)/.test(path);
  const convPath = `/:basePath?${noLangInPath ? "/:lang" : ""}${path}/`;
  return convPath;
}

function postSearchEndpoint() {
  /* eslint-disable react-hooks/rules-of-hooks */
  return useEndpoint((data, path) => ({
    url: baseUrl + "/" + path,
    method: "POST",
    data,
  }));
}

function getSearchEndpoint() {
  /* eslint-disable react-hooks/rules-of-hooks */
  return useEndpoint((path) => ({
    url: baseUrl + "/" + path,
    method: "GET",
  }));
}

const App = (props) => {
  const [searchParams, setSearchParams] = useState(initialSearchParams);
  const [viewMode, setViewMode] = useState("prhCard");
  const [currentPage, setCurrentPage] = useState(1);
  const [masonryPage, setMasonryPage] = useState(1);
  const [activeLink, setActiveLink] = useLocalStorage("activeLink", "Home");
  const [showSurvey, setShowSurvey] = useState(true);

  const closeSurvey = () => {
    setShowSurvey(false);
  };

  const onChangePage = (page, paginationFor) => {
    if (paginationFor === "masonry") {
      setMasonryPage(page);
    } else {
      setCurrentPage(page);
    }
  };

  const [search, postSearch] = postSearchEndpoint();
  function doSearch(params, path) {
    if (viewMode === "masonry") {
      setViewMode("prhCard");
    }
    setCurrentPage(1);
    setMasonryPage(1);
    postSearch(params, path);
  }

  const [trademark, getTrademark] = getSearchEndpoint();
  function doTrademarkSearch(path) {
    getTrademark(path);
  }

  const [recordal, getRecordal] = getSearchEndpoint();
  function doRecordalSearch(path) {
    getRecordal(path);
  }

  const [opposition, getOpposition] = getSearchEndpoint();
  function doOppositionSearch(path) {
    getOpposition(path);
  }

  const [international, getInternational] = getSearchEndpoint();
  function doInternationalSearch(path) {
    getInternational(path);
  }

  const withSlashOrEmpty = (param) => {
    return param ? "/" + param : "";
  };

  const supportedRoutes = [
    "search",
    "results",
    "trademark",
    "recordal",
    "opposition",
    "international",
    "tmr",
    "tmrResults",
  ];
  const isSupportedRoute = (route) => supportedRoutes.includes(route);

  const supportedLanguages = ["fi", "sv", "en"];
  const isSupportedLanguage = (lang) => supportedLanguages.includes(lang);

  let basePath = null,
    lang = null,
    route = null;

  const { dispatch } = useContext(I18nContext);

  const setPathParams = (params) => {
    lang = isSupportedLanguage(params.lang)
      ? params.lang
      : isSupportedLanguage(params.basePath)
      ? params.basePath
      : null;

    route = isSupportedRoute(params.lang)
      ? params.lang
      : isSupportedRoute(params.basePath)
      ? params.basePath
      : null;

    basePath =
      isSupportedLanguage(params.lang) ||
      (isSupportedRoute(params.lang) &&
        !isSupportedLanguage(params.basePath)) ||
      (params.basePath &&
        !isSupportedLanguage(params.basePath) &&
        !isSupportedRoute(params.basePath))
        ? params.basePath
        : !params.basePath && !isSupportedLanguage(params.lang)
        ? params.lang
        : null;
  };

  const determineRedirect = (props) => {
    if (!route && lang) return false;

    let pathRemainder = props.location.pathname;

    for (const pathParam of [lang, basePath, route])
      pathRemainder = pathRemainder.replace("/" + pathParam, "");

    return (
      <Redirect
        to={`${withSlashOrEmpty(basePath)}/${lang || "fi"}${withSlashOrEmpty(
          route
        )}${pathRemainder}`}
      />
    );
  };

  const pathParams = {
    getLang: () => lang,

    buildPath: (path) =>
      `${withSlashOrEmpty(basePath)}${withSlashOrEmpty(lang)}${withSlashOrEmpty(
        path
      )}/`,

    updateLanguage: () => {
      if (lang !== "fi" && document.documentElement.lang !== lang) {
        document.documentElement.lang = lang;
        dispatch({ type: "setLanguage", payload: lang });
      }
    },
  };

  return (
    <div>
      { <SurveyModal
        topics={["surveys"]}
        language={document.documentElement.lang}
        start={showSurvey}
        setShowSurvey={setShowSurvey}
      /> }
      <Router>
        <Switch>
          <Route
            path={buildRoutePath("/search")}
            render={(props) => {
              setPathParams(props.match.params);

              return (
                determineRedirect(props) || (
                  <SearchPage
                    searchParams={searchParams}
                    setSearchParams={setSearchParams}
                    fetchSearchResults={doSearch}
                    activeLink={activeLink}
                    setActiveLink={setActiveLink}
                    pathParams={pathParams}
                    closeSurvey={closeSurvey}
                    showSurvey={showSurvey}
                  />
                )
              );
            }}
          />
          <Route
            exact
            path={buildRoutePath("/tmr/:applicationNumber")}
            render={(props) => {
              setPathParams(props.match.params);

              return (
                determineRedirect(props) || (
                  <TmrDetailsPage
                    fetchTrademark={doTrademarkSearch}
                    trademark={trademark}
                    activeLink={activeLink}
                    setActiveLink={setActiveLink}
                    pathParams={pathParams}
                  />
                )
              );
            }}
          />
          <Route
            path={buildRoutePath("/tmr")}
            exact
            render={(props) => {
              setPathParams(props.match.params);

              return (
                determineRedirect(props) || (
                  <TmrPage
                    searchParams={searchParams}
                    setSearchParams={setSearchParams}
                    fetchSearchResults={doSearch}
                    activeLink={activeLink}
                    setActiveLink={setActiveLink}
                    pathParams={pathParams}
                  />
                )
              );
            }}
          />
          <Route
            path={buildRoutePath("/tmrResults")}
            render={(props) => {
              setPathParams(props.match.params);

              return (
                determineRedirect(props) || (
                  <SearchResultPage
                    searchParams={searchParams}
                    setSearchParams={setSearchParams}
                    viewMode={viewMode}
                    setViewMode={setViewMode}
                    searchResult={search}
                    fetchSearchResults={doSearch}
                    path="tmr"
                    onChangePage={onChangePage}
                    currentPage={currentPage}
                    masonryPage={masonryPage}
                    activeLink={activeLink}
                    setActiveLink={setActiveLink}
                    pathParams={pathParams}
                    closeSurvey={closeSurvey}
                    showSurvey={showSurvey}
                  />
                )
              );
            }}
          />
          <Route
            path={buildRoutePath("/results")}
            render={(props) => {
              setPathParams(props.match.params);

              return (
                determineRedirect(props) || (
                  <SearchResultPage
                    searchParams={searchParams}
                    setSearchParams={setSearchParams}
                    viewMode={viewMode}
                    setViewMode={setViewMode}
                    searchResult={search}
                    fetchSearchResults={doSearch}
                    path="trademark"
                    onChangePage={onChangePage}
                    currentPage={currentPage}
                    masonryPage={masonryPage}
                    activeLink={activeLink}
                    setActiveLink={setActiveLink}
                    pathParams={pathParams}
                    closeSurvey={closeSurvey}
                    showSurvey={showSurvey}
                    {...props}
                  />
                )
              );
            }}
          />
          <Route
            path={buildRoutePath(
              "/trademark/:applicationNumber/:registrationNumber?"
            )}
            render={(props) => {
              setPathParams(props.match.params);

              return (
                determineRedirect(props) || (
                  <TrademarkDetailsPage
                    fetchTrademark={doTrademarkSearch}
                    trademark={trademark}
                    activeLink={activeLink}
                    setActiveLink={setActiveLink}
                    pathParams={pathParams}
                  />
                )
              );
            }}
          />
          <Route
            path={buildRoutePath("/recordal/:applicationNumber")}
            render={(props) => {
              setPathParams(props.match.params);

              return (
                determineRedirect(props) || (
                  <RApplication
                    fetchRecordal={doRecordalSearch}
                    recordal={recordal}
                    activeLink={activeLink}
                    setActiveLink={setActiveLink}
                    pathParams={pathParams}
                  />
                )
              );
            }}
          />
          <Route
            path={buildRoutePath("/opposition/:applicationNumber")}
            render={(props) => {
              setPathParams(props.match.params);

              return (
                determineRedirect(props) || (
                  <Opposition
                    fetchOpposition={doOppositionSearch}
                    opposition={opposition}
                    activeLink={activeLink}
                    setActiveLink={setActiveLink}
                    pathParams={pathParams}
                  />
                )
              );
            }}
          />
          <Route
            exact
            path={buildRoutePath(
              "/international/:applicationNumber/:registrationNumber?"
            )}
            render={(props) => {
              setPathParams(props.match.params);

              return (
                determineRedirect(props) || (
                  <KApplication
                    fetchInternational={doInternationalSearch}
                    data={international}
                    activeLink={activeLink}
                    setActiveLink={setActiveLink}
                    pathParams={pathParams}
                  />
                )
              );
            }}
          />
          <Route
            path={buildRoutePath("/:lang?")}
            render={(props) => {
              setPathParams(props.match.params);
              const redirect = determineRedirect(props);

              if (redirect) return redirect;
              else if (isSupportedLanguage(lang))
                return (
                  <MainPage
                    searchParams={searchParams}
                    setSearchParams={setSearchParams}
                    fetchSearchResults={doSearch}
                    activeLink={activeLink}
                    setActiveLink={setActiveLink}
                    pathParams={pathParams}
                    closeSurvey={closeSurvey}
                    showSurvey={showSurvey}
                  />
                );
              else
                return (
                  <Redirect
                    to={`${withSlashOrEmpty(basePath)}/${
                      document.documentElement.lang
                    }/`}
                  />
                );
            }}
          />
        </Switch>
      </Router>
    </div>
  );
};

export default App;
